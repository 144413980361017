<script>
import request from '../../services/request'
import { mapActions } from 'vuex';
import offline from '@/mixins/offline';

export default {
    mixins: [offline],
    data() {
        return {
            eventId: '',
            participatingUsers: [],
            countRount: [],
            lap: false,
            actives: [],
            users: [],
            load: false,
            changeHere: true,
        }
    },
    methods: {
        async getUsersEvent(id) {
            this.eventId = id;
            this.load = true;
            let service = request;
            let _endpoint = `${id}/users`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.users = data;
                    this.load = false;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showResponsesUser(user) {
            Event.$emit('event-responses-user', user);
            this.$modal.show('ResponsesUser');
        },
        selectUser(dinamic) {
            let users_id = this.users.map(x => x['id']);
            let participatingUsers = this.participatingUsers;
            if (participatingUsers.length == users_id.length) {
                this.lap = true;
                this.participatingUsers = [];
            } else {
                participatingUsers.forEach(function (participants) {
                    let index = users_id.indexOf(participants);
                    users_id.splice(index, 1);
                });
            }

            // dinamica Ruleta
            let usersG = 1;
            if (dinamic.dinamic.dynamic_id == 3) {
                if (users_id.length >= 3) {
                    usersG = 3
                } else {
                    usersG = users_id.length;
                }
            }

            // Adivina la palabra
            console.log(dinamic.dinamic);
            if (dinamic.dinamic.dynamic_id == 1 || dinamic.dinamic.dynamic_id == 2) {
                if (users_id.length >= 2) {
                    usersG = 2
                } else {
                    usersG = users_id.length;
                }
            }

            // dinamica Preguntale a tu lider
            if (dinamic.dinamic.dynamic_id == 10) {
                if (users_id.length >= 4) {
                    usersG = 4
                } else {
                    usersG = users_id.length;
                }
            }

            let userId = [];
            for (let index = 0; index < usersG; index++) {
                let rand = Math.floor(Math.random() * users_id.length);
                let user_id = users_id[rand];
                userId.push(users_id[rand]);
                users_id.splice(rand, 1);
                let ind = this.countRount.findIndex((element) => element.id == user_id);
                if (ind >= 0) {
                    let infC = this.countRount[ind].count;
                    let nConunt = parseInt(infC) + 1;
                    this.countRount[ind].count = nConunt;
                } else {
                    this.countRount.push({ 'id': user_id, count: 1 });
                }
            }

            // let rand = Math.floor(Math.random() * users_id.length);
            // let userId = users_id[rand];
            this.sendUserSelected(userId);

            this.actives = userId;
            // this.participatingUsers.push(userId);
            this.participatingUsers = this.participatingUsers.concat(userId);

        },
        async sendUserSelected(userId) {
            let offline = await this.mixinCheckOfflineRouterId();
            if (!offline) {
                let _endpoint = '';
                _endpoint = `${this.eventId}/user_selected`;
                let info = {
                    user_id: userId
                };
                try {
                    let { data } = await request.post(_endpoint, info)
                    if (data) {
                        Event.$emit('participant-active', userId);
                    }
                } catch (error) {
                    this.load = false;
                    this.errors = {};
                    if (error.response) {
                        this.errors = await this.errorNotification(error);
                    }
                }
                try {
                    await new Promise((resolve, reject) => {
                        console.log(reject);
                        resolve("done");
                    }).then();
                    return true;
                } catch (error) {
                    console.log(error);
                }
            } else {
                Event.$emit('participant-active', userId);
            }
        },
        getIndicator(id) {
            if (this.actives.includes(id)) {
                return 'fa-beat-fade text-info';
            } else {
                if (this.participatingUsers.includes(id)) {
                    return 'text-success';
                } else {
                    return 'text-secondary';
                }
            }
        },
        getParcipaciones(id) {
            let count = 0;
            let exist = this.countRount.find((element) => element.id == id);
            if (exist) {
                count = exist.count;
            }
            return count;
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.getUsersEvent(id);
            let vm = this;

            window.Echo.channel(`userSelected.${id}`)
                .listen('UserSelected', (e) => {
                    let users = e.users.map(x => x['id']);
                    if (users !== vm.actives) {
                        vm.actives = users;
                        users.forEach(function (participants) {
                            let index = vm.participatingUsers.includes(participants);
                            if (!index) {
                                vm.participatingUsers.push(index, 1);
                            }
                        });

                        // console.log('participatingUsers');
                        // console.log(vm.participatingUsers);


                        // if (vm.participatingUsers.length == users_id.length) {
                        //     vm.participatingUsers = [];
                        // }

                        // vm.participatingUsers = vm.participatingUsers.concat(users);

                    }
                })
        }
    },
    created() {
        Event.$on("event-select-user", (dinamic) => {
            this.selectUser(dinamic);
        });
        Event.$on("event-user-selected", () => {
            this.actives = '';
        });
        Event.$on("event-selected-users", users => {
            if (users) {
                this.participatingUsers = users;
            }
        });
    },
}


</script>
<template>
    <div class="row" v-if="load == false">
        <div class="col-12">
            <h3 class="text-secondary">Lista de usuarios </h3>
        </div>
        <div class="col-12 list-users">
            <div class="row">
                <div class="col-12" v-for="(user, index) in users" :key="index">
                    <div class="inidicator" @click="showResponsesUser(user)">
                        <i class="fa-solid fa-circle fa-lg mr-2 punter" :class="getIndicator(user.id)"></i> <strong
                            style="font-size:16px;">{{
        user.name }} {{ user.last_name }}
                            <span style="float: right;font-size: 14px;" v-if="getParcipaciones(user.id) && lap"
                                class="badge badge-secondary">{{ getParcipaciones(user.id) }}</span>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.list-users {
    max-height: 600px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    box-shadow: 0 5px 10px #797979;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(185, 0, 0);
        border-radius: 20px;
        border: 2px solid #f1f2f3;
    }
}

.punter {
    padding: 12px 5px 10px 5px;
    border: 1px solid #7c7c7c;
    border-radius: 50%;
    font-size: 14px;
}

.inidicator {
    padding: 10px;
    color: #6c757d;
    cursor: pointer;
}

.inidicator:hover {
    border: 1px solid green;
    border-radius: 10px;
    background-color: transparent;
}
</style>