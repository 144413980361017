<template>
	<div>
		<modal name="ShowQuestionnaires" v-bind:click-to-close="false" :width="700" :adaptive="true">
			<div class="row p-4" v-if="load == false">
				<div class="col-12">
					<h4>{{ title }}</h4>
					<hr>
					<div class="form-group mb-2">
						<label for="">Nombre :</label>
						<input v-model="form.name" class="form-control" disabled>
						<div v-if="(errors && errors.name)" class="text-danger ml-3">{{ errors.name[0] }}</div>
					</div>
					<div class="form-group mb-2">
						<label for="">Descripción :</label>
						<textarea v-model="form.description" class="form-control" disabled></textarea>
						<!-- <div v-if="(errors && errors.description)" class="text-danger ml-3">{{ errors.description[0] }}</div> -->
					</div>
					<hr>
					<div class="form-group mb-2">
						<div class="row">
							<div v-if="(errors && errors.questions)" class="text-danger ml-3">{{ errors.questions[0] }}
							</div>
							<div class="col-12" v-for="(question, index_question) in form.questions" :key="index_question">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-7">
												<input type="text" v-model="question.name" placeholder="Pregunta"
													class="form-control" disabled>
												<div v-if="errors && error_array(index_question, 'name', question.id)"
													class="text-danger">{{ error_array(index_question, 'name', question.id)
													}}</div>
											</div>
											<div class="col-5">
												<select class="form-control" disabled v-model="question.question_types_id">
													<option v-for="qtype in catalogue.question_types" :key="qtype.id"
														:value="qtype.id">{{ qtype.name }}</option>
												</select>
											</div>
										</div>
										<div class="row pt-3">
											<!-- Corta -->
											<div v-if="question.question_types_id == 1" class="col-6 ml-2 border-bottom">
												Respuesta breve
											</div>

											<!-- Parrafo -->
											<div class="col-12">
												<div v-if="question.question_types_id == 2" class="col-12 border-bottom">
													Respuesta larga
												</div>
											</div>
											<!-- Opciónes -->
											<div v-if="question.question_types_id == 3 || question.question_types_id == 4 || question.question_types_id == 5"
												class="col-12">
												<div class="row">
													<div class="col-12 d-flex mt-2"
														v-for="(answare, index_answare) in question.answares"
														:key="index_answare">
														<i class="fa-regular fa-circle mt-2"
															v-if="question.question_types_id == 3"></i>
														<i class="fa-regular fa-square mt-2"
															v-if="question.question_types_id == 4"></i>
														<i class="fa-regular fa-circle-down mt-2"
															v-if="question.question_types_id == 5"></i>
														<input type="text" class="ml-2 form-control" disabled v-model="answare.name"
															placeholder="Opcion">														
													</div>													
												</div>
											</div>

										</div>
										<div class="row">
											<div class="col-12 mt-4">
												<label class="float-left d-flex">
													Obligatorio
													<switches class="mt-2 ml-3" theme="bootstrap" disabled color="info"
														v-model="question.required"></switches>
												</label>												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>					
				</div>
				<div class="col-12 mt-3">
					<div class="row">
						<div class="col-6">
							<button class="btn btn-outline-warning" @click="reset">
								<i class="fas fa-undo-alt"></i>
								Cerrar
							</button>
						</div>						
					</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-md-12 text-center p-4">
					<i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
					<h5 class="mt-3">Enviando...</h5>
				</div>
			</div>
		</modal>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '../../services/catalogue';
import Switches from 'vue-switches';

export default {
	name: "new-questionnaires",
	components: {
		Switches
	},
	data() {
		return {
			title: 'Ver formulario',
			form: {
				id: '',
				name: '',
				description: '',
				questions: [],
				_method : 'POST'
			},
			errors: {},
			catalogue: new Catalogue(),
			load: false,
		}
	},
	created() {
		Event.$on("event-show-questionnaires", id => {
			this.title = 'Ver formulario';
			if (id) {
				this.title = 'Ver formulario';
				this.get_id(id);
			}
		});
	},
	mounted() {
		this.catalogue.get_question_types();
		let id = this.$route.params.id;
		if (id) {
			this.form.proyect_id = id;
		}
	},
	methods: {
		error_array(pos, key) {
			let opt = `questions.${pos}.${key}`;
			let errors = this.errors;
			let rtn = '';
			if (errors[opt]) {
				rtn = errors[opt][0];
			}
			return rtn;

		},
		async save() {
			this.load = true;
			let _endpoint = '';
			if (this.form.id) {
				_endpoint = `/questionnaires/${this.form.id}`;
				this.form._method = 'PUT';
			} else {
				_endpoint = `/questionnaires`;
			}
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-questionnaires', '');
				}
			} catch (error) {
				this.load = false;
				this.errors = {};
				if (error.answare) {
					this.errors = await this.errorNotification(error);
				}
			}
		},
		async get_id(id) {
			this.form.id = id;
			let _endpoint = `/questionnaires/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.name = data.name;
					this.form.description = data.description;
					this.form.questions = data.questios;
				}
			} catch (error) {
				this.errors = {};
				if (error.answare) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		add_answare(index_question) {
			let resp = this.form.questions[index_question].answares;
			let data = {
				name: ''
			};
			resp.push(data);
			this.form.questions[index_question].answares = resp;
		},
		delete_question(key) {
			this.form.questions.splice(key, 1);
		},
		delete_answare(index_question, index_answare) {
			let resp = this.form.questions[index_question].answares;
			resp.splice(index_answare, 1);
			this.form.questions[index_question].answares = resp;
		},
		add_question() {
			let questions = this.form.questions
			let data = {
				name: '',
				question_types_id: 1,
				required: false,
				answares: []
			};
			questions.push(data);
			this.form.questions = questions;
		},
		reset() {
			this.load = false;
			this.errors = {};
			this.form.id = '';
			this.form.name = '';
			this.form.description = '';
			this.form.questions = [];
			this.form._method = 'POST';
			this.$modal.hide('ShowQuestionnaires');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style>.pad {
	padding: 10px !important;
}

.mx-datepicker {
	width: 100%;
}

.form-control:disabled{
    border:0px;
	border-radius: 0px;
	border-bottom: 1px solid #dee2e6;
    background-color: #ffffff;
    opacity: 1;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
</style>
