<script>
import Catalogue from '../../services/catalogue';
import request from '../../services/request'
import '../../helpers/filters'
import PictionaryComponent from '@/components/Dinamics/PictionaryComponent.vue';

export default {
    components: {
        PictionaryComponent
    },
    data(){
        return {
            load_elemnt: true,
			eventInfo: {
				id: '',
				name: '',
				date: '',
				time: '',
				event_types_id: '',
				questionnaires_id: '',
				url: '',
				participants: []
			},
			catalogue: new Catalogue(),
        }
    },
    methods: {
		async get_id(id) {
			this.eventInfo.id = id;
			let _endpoint = `/events/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.eventInfo;
					Object.keys(form).forEach(key => {
						this.eventInfo[key] = data[key];
					});
					if (data.time) {
						this.eventInfo.time = data.time.substring(0, 5);
					}
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
        
		presnetEvent(id) {
			window.open(`../#/presenter/${id}/pictionary`, 'ventana', 'width=1280,height=720,scrollbars=NO,menubar=NO,resizable=NO,titlebar=NO,status=NO');
		}
	},
	mounted() {
		this.catalogue.get_dynamic_types();
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}
	},
}
</script>
<template>
    <div class="ml-4 col-md-12 col-sm-12 col-12">
        <div class="ml-4 col-md-12 col-sm-12 col-12">
            <h1 class="box-title">Caras y gestos</h1>
            <h3 class="box-date">
                <span v-if="eventInfo.event_types_id == 1">Conectando</span>
                <span v-if="eventInfo.event_types_id == 1 || eventInfo.event_types_id == 2"> ideas </span> 
                <span v-if="eventInfo.event_types_id == 2">con la DG</span>
                <span v-if="eventInfo.event_types_id == 3">
                    CHarlando
                </span>
                -
                <span>
                    {{ eventInfo.date | dateName }}
                </span>
            </h3>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
            <pictionary-component></pictionary-component>
        </div>
        <div>
            <button class="btn btn-info btn_present btn_control" @click="presnetEvent(eventInfo.id)">
                Presentar
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
$color-primary: #3D3D3B;
$color-secondary: #76777A;
.box{
    &-title{
        font-weight: bold;
        color: $color-primary;
    }
    &-date{
        color: $color-secondary;
    }
}
</style>

