<template>
	<div class="container">
		<div class="col-md-12 col-sm-12 col-12">
			<div class="row">
				<div class="col-12">
					<h1>{{ title }}</h1>
					<h4 class="text-secondary">{{ eventInfo.type.name }} - {{ eventInfo.date | dateName }} </h4>
				</div>
			</div>
			<ResponsesUser />
			<div class="row justify-content-center">
				<div class="col-md-9 col-sm-12 col-12">
					<form-wizard v-if="tabs.length > 0" color="#ffc107" ref="wizardPresent" stepSize="lg" title=""
						subtitle="" back-button-text="Atrás" next-button-text="Siguiente" finish-button-text="Finalizar"
						@on-complete="onComplete" :start-index="eventInfo.tab_indicator">

						<tab-content title="" v-for="(tabs, index_tabs) in tabs" :key="index_tabs">
							<!-- Fases -->
							<div class="row" v-if="tabs.type == 'fase'">
								<div class="col-12 size-div text-center animate__animated animate__zoomIn">
									<img class="img-fluid"  :src="require(`../../assets/img/${tabs.image}`)">
									<h3 class="mt-4" v-if="tabs.name == 'fase 4'">
										Somos un solo Equipo, sonríe para el recuerdo.
									</h3>
								</div>
							</div>
							<!-- Components -->
							<div class="row" v-if="tabs.type == 'component'">
								<div class="col-md-12 size-div text-center animate__animated animate__zoomIn"
									:is="tabs.componentPresentar"></div>
							</div>
							<!-- Wildcards -->
							<div class="row" v-if="tabs.type == 'tabs_wildcards'">
								<div class="col-md-12 size-div text-center animate__animated animate__zoomIn"
									:is="tabs.componentPresentar"></div>
							</div>
							<!-- Tagging -->
							<div class="row" v-if="tabs.type == 'tabs_tagging'">
								<div class="col-md-12 size-div text-center animate__animated animate__zoomIn"
									:is="tabs.componentPresentar"></div>
							</div>
							<!-- Dinamics -->
							<div class="row" v-if="tabs.type == 'dinmaic'">
								<div class="col-md-12 size-div text-center animate__animated animate__zoomIn"
									:is="tabs.dinamic.pivot.component" :event="tabs.dinamic"></div>
							</div>

						</tab-content>
						<template slot="footer" scope="props">
							<!-- <div class=wizard-footer-left>
								<wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep"
									@click.native="props.prevTab()" :style="props.fillButtonStyle">Anterior</wizard-button>
							</div> -->
							<div class="wizard-footer-right hide">
								<wizard-button v-if="false" @click.native="props.nextTab()" class="wizard-footer-right"
									:style="props.fillButtonStyle">Siguiente</wizard-button>

								<!-- <wizard-button v-else @click.native="alert('Done')"
									class="wizard-footer-right finish-button" :style="props.fillButtonStyle">{{
										props.isLastStep ? 'Finalizar' : 'Siguiente' }}</wizard-button> -->
							</div>

						</template>

					</form-wizard>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import UserEventComponent from '@/components/Users/UserEventComponent.vue';
import ResponsesUser from '@/components/Questionnaire/ResponsesUserComponent.vue';

import GuessWordComponent from '@/components/Dinamics/GuessWordComponent.vue';
import PictionaryComponent from '@/components/Dinamics/Presenter/PictionaryPresenterComponent.vue';

import RouletteComponent from '@/components/Dinamics/Presenter/RoulettePresenterComponent.vue';

import GratitudePage from '../moderator/GratitudePage.vue';
import MessageWelcome from '../presenter/MessageWelcome.vue';

import WildcardPresenter from '@/components/Wildcards/WildcardPresenterComponent.vue';
import MessageEnd from './MessageEnd.vue';

import TaggingPresenter from '@/components/tagging/TaggingPresenterComponent.vue';
import { mapActions } from 'vuex';
import Catalogue from '../../services/catalogue';
import request from '../../services/request'
import '../../helpers/filters'
import 'animate.css';

export default {
	components: {
		FormWizard,
		TabContent,
		UserEventComponent,
		ResponsesUser,
		GuessWordComponent,
		PictionaryComponent,
		RouletteComponent,
		GratitudePage,
		MessageWelcome,
		WildcardPresenter,
		MessageEnd,
		TaggingPresenter
	},
	data() {
		return {
			load_elemnt: true,
			title: '',
			eventInfo: {
				id: '',
				name: '',
				date: '',
				time: '',
				data: {},
				event_types_id: '',
				questionnaires_id: '',
				url: '',
				type: '',
				tab_indicator: 0,
				participants: [],
				dinamics: [],
				noti: [],
			},
			tabs: [],
			wildcards: [],
			catalogue: new Catalogue(),
		}
	},
	methods: {
		async get_id(id) {
			this.eventInfo.id = id;
			let _endpoint = `/events/${id}?presenter=1`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.eventInfo;
					Object.keys(form).forEach(key => {
						this.eventInfo[key] = data[key];
					});

					if (data.type) {
						this.tabs = data.tabs;
					}

					if (data.dinamics) {
						this.dinamics = data.dinamics
					}

					if (data.time) {
						this.eventInfo.time = data.time.substring(0, 5);
					}
					if (data.participants) {
						let ids = data.participants.map(x => x['id']);
						this.eventInfo.participants = ids;
					}

					this.eventInfo.tab_indicator = data.tab_indicator;

					// if (data.tab_indicator != 0) {
					// 	let vm = this;
					// 	setTimeout(function () {
					// 		for (let index = 0; index <= data.tab_indicator; index++) {
					// 			vm.$refs.wizard.nextTab();
					// 		}
					// 	}, 100);

					// }
					this.changeTitle();

				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		onComplete() {

		},
		async changeTitle() {
			let index = this.eventInfo.tab_indicator;
			let tabs = this.tabs[index];
			let vm = this;
			if (tabs.type == "dinmaic") {
				if (tabs.dinamic.dynamic_id == 1) {
					this.title = "Completa palabra";
				}
				if (tabs.dinamic.dynamic_id == 2) {
					this.title = "Caras y gestos";
				}
				if (tabs.dinamic.dynamic_id == 3) {
					this.title = "Cuéntame de Cultura";
				}
			}

			if (tabs.type == "tabs_wildcards") {
				this.title = "¡Pregúntale a tu líder!​";
			}

			if (tabs.type == "fase") {
				if (tabs.name == "fase 3") {
					this.title = "¡Pregúntale a tu líder!";
				}
				if (tabs.name == "fase 4") {
					let notIds = vm.noti;
					if (notIds) {
						notIds.forEach(async function (id) {
							vm.$snotify.remove(id)
						});
					}
					vm.noti = [];
					this.title = "Despedida";
				}
			}
		},
		...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
	},
	mounted() {
		this.title = 'Bienvenida';
		this.catalogue.get_dynamic_types();
		let vm = this;
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);

			// Cambio de tabs
			window.Echo.channel(`changeTab.${id}`)
				.listen('ChangeTabs', (e) => {
					console.log(e);
					if (e) {
						let ind = e.event.tab_indicator++;
						this.eventInfo.tab_indicator = ind;
						this.changeTitle();
						this.$refs.wizardPresent.nextTab();
					}
				})

			// Select Users
			window.Echo.channel(`userSelected.${id}`)
				.listen('UserSelected', async (e) => {
					if (e) {
						let notIds = vm.noti;
						if (notIds) {
							notIds.forEach(async function (id) {
								vm.$snotify.remove(id)
							});
						}
						vm.noti = [];

						e.users.forEach(async function (participants) {
							let not = vm.$snotify.info(`${participants.name} ${participants.last_name}`, 'Participante', {
								showProgressBar: false,
								timeout: 0,
								closeOnClick: true,
							});
							vm.noti.push(not.id);
						});
					}
				})
		}
	},
}
</script>

<style scoped>
.size-div {
	max-height: 100vh;
	overflow-y: auto;
}

.btn_add {
	bottom: 20px;
	position: fixed;
	right: 20px;
}

.bg-secondary {
	background-color: #98989a;
}

.pagination {
	margin-bottom: 0;
}

.b-logo {
	border-radius: 5px 55px 55px 5px;
	width: 120px;
	z-index: 1;
}

.dt_slider::before {
	content: "";
	background-color: #6c757d;
	height: 29px;
	position: absolute;
	width: 100px;
	bottom: 0px;
	left: -100px;
}

.dt_slider {
	bottom: 0px;
	position: absolute;
	width: calc(100% - 130px);
	border-radius: 0px 0px 5px 0px;
}

.shadow {
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.btn_present {
	position: absolute;
	left: 41%;
	margin-top: 75px;
	padding: 9px !important;
	border-radius: 30px;
	width: 150px;
}

.wizard-btn {
	margin-top: 40px;
	width: 150px !important;
	height: 45px !important;
	background-color: transparent !important;
	color: #fbd102 !important;
	border-width: 1px !important;
	border-radius: 40px !important;
}

.wizard-btn:hover {
	background-color: #fbd102 !important;
	color: #000 !important;
}

.wizard-card-footer {
	display: none !important;
}
</style>
