<script>
import moment from 'moment';
// import TimerComponent from './TimerComponent.vue';
import request from '../../services/request'
import TimerComponent from './TimernNewComponent.vue'
import offline from '@/mixins/offline';

export default {
    mixins: [offline],
    components: { TimerComponent },
    props: {
        event: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            elapsedTime: moment.duration(0),
            pictionary: null,
            pictionary_options: {
                word: '',
                time: ''
            },
            currentWord: null,
            words_use: null,
            max_attempts_word: 8,
            attempts: 0,
            available_attempts: 5,
            success_attempts: 0,
            loadingRandom: false,
            status_game: false,
            finish: false,
            timeOn: false,
            /* done_audio: '../../assets/audio/Bien_001.mp3',
            error_audio: '../../assets/audio/Bien_006.mp3',
            notification_audio: '../../assets/audio/Notificacion_03.wav' */
        }
    },
    created() {
        let dinamic = this.event;
        this.pictionary_options.word = dinamic.data.word;
        this.pictionary_options.time = dinamic.data.time;
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            if (!this.data) {
                this.pictionary = this.pictionary_options;
            } else {
                this.pictionary = this.data;
            }
            // Event.$emit('convert-timer', this.pictionary.time);
        },
        /* randomPhrase() {
            if (!this.words_use) {
                this.words_use = JSON.parse(JSON.stringify(this.pictionary));
            }
            const phrase = this.words_use[Math.floor(Math.random() * this.words_use.length)];
            const index = this.words_use.findIndex(obj => obj.id === phrase.id);
            if (phrase) {
                this.currentWord = phrase;
                this.loadingRandom = true;
            } else {
                this.loadingRandom = false;
                this.currentWord = {
                    id: 0,
                    message: '',
                    data: {
                        word: 'Fin del Juego!',
                    }
                }
            }
            if (index !== -1) {
                this.words_use.splice(index, 1);
            }
        }, */
        startGame() {
            // this.$refs.audioStart.play();
            // Event.$emit('start-timer', this.pictionary_options.time);
            this.attempts += 1;
            this.status_game = true;

        },
        restartGame() {
            setTimeout(() => {
                this.getData();
            }, 2000);
        },
        gameResult(result) {

            if (result == 1) {
                //this.$refs.audioDone.play();
                this.loadingRandom = false;
                this.status_game = false;
                this.success_attempts += 1;
                this.finish = true;
                this.pictionary = {
                    word: '¡Felicidades adivinaste la palabra!',
                }
                // Event.$emit('finish-timer');
                this.max_attempts_word = 8;
                // this.restartGame();
            } else if (result == 0) {
                //this.$refs.audioError.play();
                this.max_attempts_word -= 1;
                if (this.max_attempts_word === 0) {
                    this.loadingRandom = false;
                    this.status_game = false;
                    this.finish = true;
                    this.pictionary = {
                        word: '¡No adivinaste la palabra!'
                    }
                    // Event.$emit('finish-timer');
                    this.max_attempts_word = 8;
                    // this.restartGame();
                }
            } else {
                // Event.$emit('finish-timer');
                this.loadingRandom = false;
                this.status_game = false;
                this.finish = true;
                this.pictionary = {
                    word: 'El tiempo ha finalizado y no lograron adivinar la palabra, pero la siguiente vez ¡lo conseguirán!, porque nada nos detiene.​',
                }
                this.max_attempts_word = 8;
                // this.restartGame();
            }

            if (this.finish) {
                let dinamic = this.event;
                dinamic.status = result;
                Event.$emit('finish-game', dinamic);
            }
        },
        resetGame() {
            this.words_use = null;
            this.currentWord = null;
            this.attempts = 0;
            this.available_attempts = 5;
            this.success_attempts = 0;
            this.loadingRandom = false;
            this.status_game = false;
        },
        verifyUseWords(objet) {
            this.words_use = this.getWordsUse() == null ? [] : this.getWordsUse();
            console.log(this.words_use);
            if (this.words_use !== null || this.words_use !== '') {
                const finder = this.words_use.find(word => word.phrase === objet.phrase);
                if (finder) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        wrongAttempts() {

        },
        getWordsUse() {
            return this.$ls.get('words');
        },
        async sendStatus() {
            let offline = await this.mixinCheckOfflineRouterId();
            if (!offline) {
                let _endpoint = '';
                let event = this.event;
                event.max_attempts_word = this.max_attempts_word;
                event.success_attempts = this.success_attempts;
                event.status_game = this.status_game;
                event.finish = this.finish;

                _endpoint = `${this.event.pivot.events_id}/status_game`;

                try {
                    let { data } = await request.post(_endpoint, event)
                    if (data) {
                        console.log(data);
                    }
                } catch (error) {
                    this.load = false;
                    this.errors = {};
                    if (error.response) {
                        this.errors = await this.errorNotification(error);
                    }
                }
            }
        },
    },
    watch: {
        timeOn() {

        },
        success_attempts() {
            this.sendStatus();
        },
        max_attempts_word() {
            this.sendStatus();
        },
        status_game() {
            this.sendStatus();
        }
    },
}
</script>
<template>
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-12">
            <div>
                <h4 class="box-word">
                    <timer-component :prop_time="pictionary_options.time" :prop_start="status_game"
                        @finish="gameResult(2)"></timer-component>
                </h4>
            </div>
            <div class="box-border mb-2 box-shadow">
                <h4 class="box-word" v-if="pictionary">
                    {{ pictionary.word }}
                </h4>
                <h4 class="box-word" v-else>
                    ¡Comenzar a jugar!
                </h4>
                <h4 class="">

                </h4>
            </div>
            <div class="box-status-game">
                <!-- <h6>Rondas: {{ attempts }} / {{ available_attempts }}</h6>
                <h6>Rondas acertada: {{ success_attempts }}</h6> -->
                <h6>Solo tienes: {{ max_attempts_word }} {{ max_attempts_word == 1 ? 'intento' : 'intentos' }}</h6>
            </div>
            <div class="btn-group">
                <!-- <button v-if="!loadingRandom && attempts < available_attempts" class="btn btn-primary btn-size" @click="randomPhrase">
                    Obtener palabra! </button> -->
                <button v-if="!status_game && attempts < available_attempts && finish == false"
                    class="btn btn-outline-primary" @click="startGame"> ¡Comenzar el juego! </button>
                <button v-if="status_game" class="btn btn-success btn-responsive" @click="gameResult(1)"> ¡Correcto!
                </button>
                <button v-if="status_game" class="btn btn-danger btn-responsive" @click="gameResult(0)"> ¡Incorrecto!
                </button>
                <!-- <button v-if="!status_game && attempts == available_attempts" class="btn btn-primary" @click="resetGame">
                    Reiniciar Juego! </button> -->
            </div>
        </div><!-- 
        <audio ref="audioDone" :src="done_audio"></audio>
        <audio ref="audioError" :src="error_audio"></audio>
        <audio ref="audioStart" :src="notification_audio"></audio> -->
    </div>
</template>

<style lang="scss" scoped>
.box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    gap: 10px;

    &-shadow {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &-size {
        width: 50%;
    }

    &-border {
        padding: 20px;
        border: 4px solid #FFD100;
        width: 100%;
        height: auto;
        border-radius: 20px;
    }

    &-center {
        margin: auto;
        text-align: center;
        align-self: center;
    }

    &-word {
        text-align: center;
        font-weight: bold;
        color: #76777A;
    }

    &-status-game {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #76777A;
        font-size: small;
    }
}

.btn {

    &-primary {
        color: white !important;
        background: #F29200;
        border: 2px solid #F29200 !important;
    }

    &-group {
        width: 100%;
        margin-bottom: 10px;
    }

    &-outline-primary {
        color: #F29200;
        background: white;
        border: 2px solid #F29200 !important;
    }
}

@media (max-width: 425px) {
    .btn-responsive {
        font-size: 14px;
    }
}
</style>