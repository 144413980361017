<template>
    <modal :name="modal_id" v-bind:click-to-close="false" :width="700" :adaptive="true">
        <div class="row p-4">
            <div class="col-12 border-bottom">
                <h4>Importación de participantes</h4>
            </div>
            <div class="col-12 my-4" v-if="pendingImport">
                <div class="text-center">
                    <h2> {{ parseInt( ( pendingImport.processed_rows / pendingImport.total_rows ) * 100 )}}%</h2>
                    <h5>No se completo la ultima importación</h5>
                </div>
                <div class="text-center pt-2">
                    <a @click="continueImport()" class="btn btn-outline-success mr-2">Continuar con la importación</a>
                    <a @click="pendingImport = null" class="btn btn-outline-warning ml-2">Ignorar</a>
                </div>
            </div>
            <div class="col-12 my-4" v-else-if="!pendingImport && !importComplete">
                <div class="row" v-if="importProgress!=null">
                    <div class="col-md-12 text-center p-4">
                        <h2> {{ importProgress }}%</h2>
                        <h5>Importando datos</h5>
                        <p>Esta acción puede durar unos minutos...</p>
                    </div>
                </div>
                <div class="row" v-else-if="!uploading">
                    <div class="col-12">
                        <div class="form-group mb-2">
                            <a href="/participant_template.xlsx" target="_blank">Descargar plantilla</a>
                        </div>
                        <div class="form-group mb-2 pt-4">
                            <label for="">Cargar plantilla de participantes</label>
                            <div>
                                <input @change="fileUpdate" :ref="`template_file`" type="file" class="" accept=".xlsx, .xls">
                            </div>
                            <div v-if="checking_file">
                                <span>Verificando archivo...</span> <span class="d-inline-block px-2"> <i class="fa fa-clock-o" aria-hidden="true"></i> </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-else-if="uploading">
                    <div class="col-md-12 text-center p-4">
                        <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                        <h5 class="mt-3">Subiendo Archivo...</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 my-4" v-if="!pendingImport && importComplete">
                <div class="text-center">
                    <h2> 100%</h2>
                    <h5>El archivo ha sido importado con éxito</h5>
                </div>
            </div>
            <div class="col-12 mt-4">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-outline-warning" @click="closeModal()">
                            <i class="fas fa-undo-alt"></i>
                            Cerrar
                        </button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-outline-success" v-if="document_file && document_is_ok && all_ok && importProgress==null && send==false" @click="uploadFile()">
                            <i class="fas fa-save"></i>
                            Importar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import request from '../../services/request'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    name: "participants-import",
    props: {
        modal_id: {
            type: String,
            default: "ParticipantsImport"
        },
    },
    data() {
        return {
            errors: {},
            send: false,
            pendingImport: null,
            importProgress: null,
            importComplete: false,
            uploading: false,
            document_file: null,
            document_is_ok: null,
            checking_file: false,
            totalRows: 0,
            all_ok: true,
            document_headers: [
                'NUMERO_EMPLEADO',
                'NOMBRE',
                'APELLIDO',
                'EMAIL',
                'TELEFONO'
            ],
        }
    },
    created() { },
    methods: {
        fileUpdate(event) {
            let vm = this;
            vm.document_file = null;
            if (event.target.files[0]) {
                let temp = event.target.files[0];
                const accepted = [
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ];
                if ( accepted.includes(temp.type) ) {
                    vm.document_file = temp;
                    vm.checkFile();
                } else {
                    vm.$refs.template_file.value = null;
                    vm.$snotify.error('Debes cargar un archivo de Excel.')
                }
            }
        },
        async checkFile() {
            let vm = this;
            vm.checking_file = true;
            vm.document_is_ok = null;
            if (vm.document_file) {
                try {
                    let reader = new FileReader();
                    reader.onload = async e => {
                        let data = e.target.result;
                        // console.log("data: ", data);
                        this.wb = XLSX.read(data, { type: "array" });
                        // console.log("wb: ", this.wb);
                        let jsonData = XLSX.utils.sheet_to_json(this.wb.Sheets[this.wb.SheetNames[0]]);
                        vm.totalRows = jsonData.length;
                        jsonData.splice(100, (jsonData.length - 1) );
                        // console.log("jsonData: ", jsonData);
                        await vm.checkRowByRow(jsonData);
                    };
                    reader.readAsArrayBuffer(vm.document_file);
                    vm.document_is_ok = true;
                } catch (error) {
                    vm.$snotify.error('El documento no cumple con los encabezados necesarios.');
                    vm.$refs.template_file.value = null;
                    vm.document_is_ok = false;
                }
            } else {
                vm.$snotify.error('Debe seleccionar un archivo para verificar.');
            }
            setTimeout(() => {
                vm.checking_file = false;
            }, 100);
        },
        async checkRowByRow(data_json) {
            let vm = this;
            vm.document_headers.forEach(_h => {
                vm.errors[_h] = 0;
            });
            data_json.forEach(element => {
                vm.document_headers.forEach(_h => {
                    // eslint-disable-next-line
                    vm.errors[_h] += ( element.hasOwnProperty(_h)  ) ? 0 : 1;
                });
            });
            vm.all_ok = true;
            vm.document_headers.forEach(_h => {
                if (vm.errors[_h] > 0) {
                    vm.all_ok = false;
                }
            });
            if (vm.all_ok) {
                vm.$snotify.success('El archivo está listo para subir.');
            } else {
                vm.$snotify.error('No se han encontrado los encabezados requeridos');
                vm.$refs.template_file.value = null;
            }
        },
        async uploadFile() {
            let vm = this
            if (vm.document_file && vm.document_is_ok && vm.all_ok) {
                if (!this.uploading) {
                    this.uploading = true;
                    const service = request
                    let formData = new FormData();
                    formData.append('file', vm.document_file);
                    formData.append('total_rows', vm.totalRows);
                    formData.append('model', 'Participants');
                    try {
                        vm.send = true;
                        let {data} = await service.post('/imports', formData);
                        // eslint-disable-next-line
                        if(data && data.hasOwnProperty('imported')) {
                            vm.vefityImport(data.imported);
                        } else {
                            vm.$snotify.warning('Oops. Algo salio mal.');
                        }
                    } catch (error) {
                        console.error(error);
                        vm.$snotify.warning('Oops. Algo salio mal.');
                    }
                    this.uploading = false;
                }
            }
        }, 
        async continueImport() {
            const vm = this
            let _temp = JSON.parse(JSON.stringify(vm.pendingImport));
            vm.pendingImport = null;
            vm.vefityImport(_temp);
        },

        async vefityImport(dataImport) {
            let vm = this;
            const service = request
            let _progress = ( dataImport.processed_rows / dataImport.total_rows ) * 100;
            vm.importProgress = parseInt(_progress);
            if (dataImport.complete) {
                Event.$emit('event-import-participants', dataImport.users);
                vm.$snotify.success('La importación se a completado con éxito.');
                this.importProgress = null;
                this.importComplete = true;
            } else {
                await setTimeout(async () => {
                    let formData = new FormData();
                    formData.append('id', dataImport.id);
                    formData.append('model', 'Participants');
                    let {data} = await service.post('/imports/'+dataImport.id, formData);
                    // eslint-disable-next-line
                    if(data && data.hasOwnProperty('imported')) {
                        vm.vefityImport(data.imported);
                    } else {
                        vm.$snotify.warning('Oops. Algo salio mal.')
                    }
                }, 300);
            }
        },
        reset() {
            let vm = this;
            vm.errors = {};
            vm.pendingImport = null;
            vm.importProgress = null;
            vm.importComplete = false;
            vm.uploading = false;
            vm.document_file = null;
            vm.document_is_ok = null;
            vm.checking_file = false;
            vm.totalRows = 0;
            vm.all_ok = true;
        },
        closeModal() {
            let vm = this;
            vm.send = false;
            this.$modal.hide(vm.modal_id);
            vm.$emit('modal-close');
            vm.reset();
            vm.getLastImport();
        },
        async getLastImport() {
            let vm = this;
            const service = request
            let formData = new FormData();
            formData.append('model', 'Participants');
            try {
                let {data} = await service.post('/imports/last', formData);
                // eslint-disable-next-line
                if (data && data.hasOwnProperty('complete') && !data.complete) {
                    this.pendingImport = data;
                }
            } catch (error) {
                vm.$snotify.warning('no se pudo obtener la ultima importación.')
            }
        },
    },
    mounted() {
        let vm = this;
        vm.getLastImport();
    },
}
</script>
<style>
.pad {
    padding: 10px !important;
}

.mx-datepicker {
    width: 100%;
}
</style>
 