<script>
import { mapActions } from 'vuex';
import CustomPagination from '@/components/CustomPagination.vue';
import request from '../../../services/request'
import '../../../helpers/filters'
import logbooks from '@/services/logbooks';
export default {
    components: {
        CustomPagination,
    },
    data() {
        return {
            search: {
                name: ''
            },
            events: [{
                data: []
            }],
            pagination: {},
            currentDate: new Date(),
            loadingData: true,
        }
    },
    computed: {
        legendPaginate() {
            let vm = this;
            // eslint-disable-next-line
            if (vm.pagination && vm.pagination.hasOwnProperty('from') && vm.pagination.hasOwnProperty('to') && vm.pagination.hasOwnProperty('total') && vm.pagination.total > 0) {
                return `Mostrando del <strong>${vm.pagination.from}</strong> al <strong>${vm.pagination.to}</strong> de <strong>${vm.pagination.total}</strong> filas`;
            } else {
                return null;
            }
        },
        pagination_current_page() {
            let vm = this;
            // eslint-disable-next-line
            return (vm.pagination && vm.pagination.hasOwnProperty('current_page') && vm.pagination.current_page) ? vm.pagination.current_page : null;
        },
    },
    methods: {
        deleted: function (id) {
            let tit = 'Cancalar evento';
            let txt = 'Seguro que quiere cancelar el evento';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/events/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async list(page = 1, time = 1) {
            let vm = this;
            let service = request;
            this.loadingData = true;
            let search = this.search;
            try {
                let _endpoint = '/events';
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    this.events = data.data;
                    vm.pagination = data;
                }
            } catch (error) {
                if (time < 3) {
                    setTimeout(async () => {
                        await vm.fecthData(page = 1, time + 1);
                    }, 400);
                }
                this.errors = await this.errorNotification(error);
            }
        },
        downLoadBookLog(id) {
            logbooks.downloadPdf(id)
        },
        downLoadReportQuestion(id) {
            logbooks.downLoadReportQuestion(id)
        },
        initEvent(id) {
            window.open(`../#/moderator/${id}`, 'ventana', 'width=1280,height=720');
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    mounted() {
        this.list();
    }
}
</script>

<template>
    <div class="col-12">
        <div class="row">
            <div class="col-12 mt-3">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Historial de eventos</h3>
                        <router-link :to="{ name: 'form_events' }" class="btn btn-sm btn-success float-right">
                            <i class="fa fa-plus"></i> Agregar
                        </router-link>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered text-center text-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Título</th>
                                        <th>Fecha</th>
                                        <th>Tipo de evento</th>
                                        <th>Cuestionario</th>
                                        <th>Creador</th>
                                        <th>Moderador</th>
                                        <th># Participantes</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody v-if="events.length > 0">
                                    <tr v-for="(event, index) in events" :key="index">
                                        <td>{{ event.id }}</td>
                                        <td>{{ event.name }}</td>
                                        <td>{{ event.date | dateHuman }} {{ event.time }}</td>
                                        <td>{{ event.type.name }}</td>
                                        <td>
                                            <p v-if="event.questionnaires">{{ event.questionnaires.name }}</p>
                                        </td>
                                        <td>
                                            <p v-if="event.created_user">{{ event.created_user.name }}</p>
                                        </td>
                                        <td>
                                            <p v-if="event.moderator">{{ event.moderator.name }}</p>
                                        </td>
                                        <td>{{ event.participants.length }}</td>
                                        <td>
                                            <router-link v-if="currentDate < new Date(`${event.date}T${event.time}`)"
                                                :to="`/events/form/${event.id}`"
                                                class="btn btn-primary btn-sm hint--bottom" aria-label="Editar evento">
                                                <i class="fas fa-edit "> </i>
                                            </router-link>
                                            <!-- <button type="button"  v-if="currentDate < new Date(`${event.date}T${event.time}`)"  @click="deleted(event.id)"
                                                class="m-1 btn-danger btn btn-sm ">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </button> -->

                                            <router-link :to="`/moderator/${event.id}`" v-if="!event.log_book"
                                                target='_blank' class="m-1 btn btn-success btn-sm hint--bottom"
                                                aria-label="Presentar evento">
                                                <i class="fa-solid fa-window-restore"></i>
                                            </router-link>
                                            <button class="m-1 btn btn-warning  hint--bottom" v-if="event.log_book"
                                                aria-label="Descargar minuta"
                                                @click="downLoadBookLog(event.log_book.id)">
                                                <i class="fas fa-file-download" style="font-size: 18px;"></i>
                                            </button>
                                            <router-link v-if="currentDate > new Date(`${event.date}T${event.time}`)"
                                                :to="`/events/show/${event.id}`" type="button"
                                                class="m-1 btn btn-secondary btn-sm hint--bottom"
                                                aria-label="Información del evento">
                                                <i class="fa fa-eye"></i>
                                            </router-link>
                                            <button @click="downLoadReportQuestion(event.id)" target='_blank'
                                                class="m-1 btn btn-info btn-sm hint--bottom hint--small"
                                                aria-label="Descargar respuestas">
                                                <i class="fa-solid fa-clipboard-question"></i>
                                            </button>

                                            <!-- <router-link
                                                :to="{ name: 'moderator', params: { id: event.id }, query: { offline: true } }"
                                                v-if="!event.log_book" target='_blank'
                                                class="m-1 btn btn-dark btn-sm hint--bottom hint--small"
                                                aria-label="Descargar respuestas">
                                                <i class="fa-solid fa-plug-circle-xmark"></i>
                                            </router-link> -->
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td align="center" colspan="9">Sin resultados.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between align-items-top">
                                <div class="pagination-information">
                                    <div v-if="legendPaginate" v-html="legendPaginate"></div>
                                </div>
                                <div class="pagination">
                                    <CustomPagination :pagination="pagination" :limit="7" v-on:change-page="list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.pagination {
    margin-bottom: 0;
}
</style>
