<script>
import { mapGetters, mapActions } from 'vuex';
import QuestionnaireForm from '@/components/Questionnaire/QuestionnaireFormCompomponent.vue';
import QuestionnaireShow from '@/components/Questionnaire/QuestionnaireShowCompomponent.vue';
import request from '../../services/request'
export default {
	components: {
		QuestionnaireForm,
		QuestionnaireShow
	},
	data() {
		return {
			search: {
				name: ''
			},
			questionnaires: []
		}
	},
	methods: {
		newModalQuestionnaure(id) {
			Event.$emit('event-new-questionnaires', id);
			this.$modal.show('NewQuestionnaires');
		},
		showModalQuestionnaure(id) {
			Event.$emit('event-show-questionnaires', id);
			this.$modal.show('ShowQuestionnaires');
		},
		deleted: function (id) {
			let tit = 'Eliminar formulario';
			let txt = 'Seguro que quiere eliminar al formulario';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/questionnaires/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		async list(page = 1) {
			let service = request;
			let _endpoint = '/questionnaires';
			try {
				let { data } = await service.list(_endpoint, page, {})
				if (data) {
					this.questionnaires = data;
				}
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	mounted() {
		this.list();
	},
	created() {
		Event.$on("event-load-questionnaires", () => {
			this.list();
		});
	},
}
</script>

<template>
	<div class="col-12 mt-3">
		<QuestionnaireForm />
		<QuestionnaireShow />
		<div class="card">
			<div class="card-header">
				<h3 class="card-title">Lista de formularios</h3>
				<button class="btn btn-sm btn-success float-right" @click="newModalQuestionnaure('')">
					<i class="fa fa-plus"></i> Agregar
				</button>
			</div>
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>#</th>
								<th>Nombre</th>
								<th>Descripción</th>
								<th>Núm. Preguntas</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="questionnaires">
							<tr v-for="(question, index) in questionnaires.data" :key="index">
								<td>{{ question.id }}</td>
								<td>{{ question.name }}</td>
								<td>{{ question.description }}</td>
								<td>{{ question.questios.length }}</td>
								<td>
									<button v-if="authUser.profiles_id == 1" @click=newModalQuestionnaure(question.id) class="m-1 btn btn-primary btn-sm">
										<i class="fas fa-edit "> </i>
									</button>
									<button v-if="authUser.profiles_id == 1" type="button" @click="deleted(question.id)" class="m-1 btn-danger btn btn-sm ">
										<i class="fa fa-trash"></i>
									</button>
									<button v-if="authUser.profiles_id == 2" @click="showModalQuestionnaure(question.id)" type="button" class="m-1 btn btn-secondary btn-sm">
										<i class="fa fa-eye"></i>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
