<template>
	<div class="container">
		<div class="col-md-12 col-sm-12 col-12">
			<div class="row">
				<div class="col-12 col-md-9">
					<h1>{{ title }}</h1>
					<h4 class="text-secondary">{{ eventInfo.type.name }} - {{ eventInfo.date | dateName }} </h4>
				</div>
				<div class="col-3 col-md-3 flex-row-reverse d-none d-md-flex d-lg-flex">
					<p class="ml-3 " style="flex; font-size: 23px;">Conectado<br>entre <span
							class="text-danger">líderes</span></p>
					<img src="../../assets/img/logo_lideres.png" style="width: 65px; height: 65px;" class="img-fluid"
						alt="">
				</div>
			</div>
			<ResponsesUser />
			<div class="row">
				<div class="col-md-9">
					<form-wizard v-if="tabs.length > 0" color="#ffc107" ref="wizard" stepSize="lg" title="" subtitle=""
						back-button-text="Atras" next-button-text="Siguiente" finish-button-text="Finalizar"
						@on-complete="onComplete" :start-index="eventInfo.tab_indicator">

						<tab-content title="" v-for="(tab, index_tab) in tabs" :key="index_tab"
							:before-change="() => validateStep(tab, index_tab)">
							<!-- Fases -->
							<div class="row" v-if="tab.type == 'fase'">
								<div class="col-12 text-center animate__animated animate__zoomIn">
									<img class="img-fluid" :src="require(`../../assets/img/${tab.image}`)">
									<h3 class="mt-4" v-if="tab.name == 'fase 4'">
										Somos un solo Equipo, sonríe para el recuerdo.
									</h3>
								</div>
							</div>
							<!-- Components -->
							<div class="row" v-if="tab.type == 'component'">
								<div class="col-md-12 text-center animate__animated animate__zoomIn"
									:is="tab.componentModerator"></div>
							</div>
							<!-- Wildcards -->
							<div class="row" v-if="tab.type == 'tabs_wildcards'">
								<div class="col-md-12 text-center animate__animated animate__zoomIn"
									:is="tab.componentModerator"></div>
							</div>
							<!-- Tagging -->
							<div class="row" v-if="tab.type == 'tabs_tagging'">
								<div class="col-md-12 text-center animate__animated animate__zoomIn"
									:is="tab.componentModerator" :taggings="`[${eventInfo.data}]`"></div>
							</div>
							<!-- Dinamics -->
							<div class="row" v-if="tab.type == 'dinmaic'">
								<div class="col-md-12 size-div text-center animate__animated animate__zoomIn"
									:is="tab.dinamic.pivot.component" :event="tab.dinamic"></div>
							</div>

						</tab-content>

						<template slot="footer" scope="props">
							<!-- <div class=wizard-footer-left>
								<wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep"
									@click.native="props.prevTab()" :style="props.fillButtonStyle">Anterior</wizard-button>
							</div> -->
							<div class="wizard-footer-right">
								<wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()"
									class="wizard-footer-right" :style="props.fillButtonStyle">Siguiente</wizard-button>

								<!-- <wizard-button v-else @click.native="alert('Done')"
									class="wizard-footer-right finish-button" :style="props.fillButtonStyle">{{
										props.isLastStep ? 'Finalizar' : 'Siguiente' }}</wizard-button> -->
							</div>

						</template>

						<button v-if="checkOffline()" class="btn btn-outline-warning btn_present btn_control"
							@click="presnetEvent(eventInfo.id)">
							Presentar
						</button>
					</form-wizard>
				</div>
				<div class="col-md-3">
					<UserEventComponent />
				</div>
				<!-- <button class="btn btn-lg btn-danger rounded-circle" style="position: fixed; bottom: 40px; right: 40px;">
					<i class="fa fa-question fa-2x"></i>
				</button> -->
			</div>
		</div>
	</div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import UserEventComponent from '@/components/Users/UserEventComponent.vue';
import ResponsesUser from '@/components/Questionnaire/ResponsesUserComponent.vue';

import GuessWordComponent from '@/components/Dinamics/GuessWordComponent.vue';
import PictionaryComponent from '@/components/Dinamics/PictionaryComponent.vue';
import RouletteComponent from '@/components/Dinamics/RouletteComponent.vue';

import GratitudePage from '../moderator/GratitudePage.vue';
import MessageWelcome from '../presenter/MessageWelcome.vue';

import WildcardPresenter from '@/components/Wildcards/WildcardPresenterComponent.vue';
import LogBookForm from '@/components/LogBook/LogBookForm.vue';

import TaggingModerator from '@/components/tagging/TaggingModeratorComponent.vue';
import { mapActions } from 'vuex';

import Catalogue from '../../services/catalogue';
import request from '../../services/request'
import '../../helpers/filters'
import 'animate.css';

import offline from '@/mixins/offline';

export default {
	mixins: [offline],
	components: {
		FormWizard,
		TabContent,
		UserEventComponent,
		ResponsesUser,
		GuessWordComponent,
		PictionaryComponent,
		RouletteComponent,
		GratitudePage,
		MessageWelcome,
		WildcardPresenter,
		LogBookForm,
		TaggingModerator
	},
	data() {
		return {
			load_elemnt: true,
			title: '',
			eventInfo: {
				id: '',
				name: '',
				date: '',
				time: '',
				data: '',
				event_types_id: '',
				questionnaires_id: '',
				url: '',
				type: '',
				tab_indicator: 0,
				participants: [],
				dinamics: [],
				game_result: {}
			},
			game_result: null,
			participanUse: '',
			tabs: [],
			wildcards: [],
			changeHere: true,
			catalogue: new Catalogue(),
		}
	},
	methods: {
		checkOffline() {
			let offline = localStorage.getItem(`event_${this.eventInfo.id}_offline`);
			return !offline;
		},
		async get_id(id) {
			this.eventInfo.id = id;
			let _endpoint = `/events/${id}?presenter=1`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.eventInfo;
					Object.keys(form).forEach(key => {
						this.eventInfo[key] = data[key];
					});

					if (data.type) {
						this.tabs = data.tabs;
					}

					if (data.dinamics) {
						this.dinamics = data.dinamics
					}

					if (data.time) {
						this.eventInfo.time = data.time.substring(0, 5);
					}
					if (data.participants) {
						let ids = data.participants.map(x => x['id']);
						this.eventInfo.participants = ids;
					}

					this.eventInfo.tab_indicator = data.tab_indicator;
					// if (data.tab_indicator != 0) {
					// 	let vm = this;
					// 	setTimeout(function () {
					// 		for (let index = 0; index < data.tab_indicator; index++) {
					// 			console.log(index);
					// 			vm.$refs.wizard.nextTab();
					// 		}
					// 	}, 1000);

					// }
					this.changeTitle();
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async validateStep(tab, index) {
			index++;
			let next = true;
			this.eventInfo.tab_indicator = index;

			let info = {
				'tab_indicator': index
			}

			if (tab.type == 'dinmaic') {
				info.tab = tab;
				info.user = this.participanUse;
				info.game_result = this.game_result;
				if (this.game_result === null) {
					this.$snotify.error('No puede avanzar hasta terminar el juego', '', {
						timeout: 8000,
						showProgressBar: true,
						closeOnClick: true,
					})
					next = false;
				}
			}

			if (next) {
				this.changeTitle();
				

					if (this.changeHere) {
						this.validateNextTab(index);
						let offline = await this.mixinCheckOfflineRouterId();
						if (!offline) {
						let _endpoint = '';
						_endpoint = `${this.eventInfo.id}/change_tab`;

						try {
							let { data } = await request.post(_endpoint, info)
							if (data) {
								if (data) {
									this.game_result = null;
								}
							}
						} catch (error) {
							this.load = false;
							this.errors = {};
							if (error.response) {
								this.errors = await this.errorNotification(error);
							}
						}
					}
				}
			}

			this.changeHere = true;
			return next;
		},
		validateNextTab(index) {
			let tabs = this.tabs[index];
			if (tabs.type == "dinmaic") {
				Event.$emit('event-select-user', tabs);
			} else {
				Event.$emit('event-user-selected');
			}
		},
		onComplete() {

		},
		changeTitle() {
			let index = this.eventInfo.tab_indicator;
			let tabs = this.tabs[index];
			if (tabs.type == "dinmaic") {
				if (tabs.dinamic.dynamic_id == 1) {
					this.title = "Completa palabra​";
				}
				if (tabs.dinamic.dynamic_id == 2) {
					this.title = "Caras y gestos";
				}
				if (tabs.dinamic.dynamic_id == 3) {
					this.title = "Cuéntame de Cultura​";
				}
			}

			if (tabs.type == "tabs_wildcards") {
				this.title = "¡Pregúntale a tu líder!​";
			}

			if (tabs.type == "fase") {
				if (tabs.name == "fase 3") {
					this.title = "¡Pregúntale a tu líder!​";
					// LLamar participantes 
					let tabs = {
						dinamic: {
							dynamic_id: 10
						}
					}
					Event.$emit('event-select-user', tabs);
				}
				if (tabs.name == "fase 4") {
					this.title = "Despedida";
				}
			}
		},
		presnetEvent(id) {
			this.$swal({
				title: 'Alerta',
				text: 'Cuide de compartir la pantalla correcta para no mostrar datos delicados, correo o WhatsApp',
				icon: 'warning',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'Ok',
			}).then(() => {
				window.open(`../#/presenter/${id}`, 'ventana', 'width=1280,height=720,scrollbars=NO,menubar=NO,resizable=NO,titlebar=NO,status=NO');
			});
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	created() {
		Event.$on("finish-game", (dinamic) => {
			this.game_result = dinamic;
		});
		Event.$on("participant-active", (participant) => {
			this.participanUse = participant;
		});
	},
	mounted() {
		this.title = 'Bienvenida';
		this.catalogue.get_dynamic_types();
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}

		// Guardar si esta en modo Offline
		let offline = this.$route.query.offline;
		localStorage.setItem(`event_${id}_offline`, offline);

		if (offline) {
			this.$swal({
				title: "Estas en modo offline",
				text: "En este modo no puede presentar, es solo para ver el moderador.",
				icon: "warning",
				confirmButtonColor: '#dc3545',
				confirmButtonText: 'Continuar',
			})
		} else {
			localStorage.removeItem(`event_${id}_offline`);
		}

		this.mixinCheckOfflineId(id);

		let vm = this;
		// Cambio de tabs
		window.Echo.channel(`changeTab.${id}`)
			.listen('ChangeTabs', (e) => {
				if (e) {
					if (e.tab_indicator != vm.eventInfo.tab_indicator) {
						vm.changeHere = false;
						vm.$refs.wizard.nextTab();

					}
				}
			})
	}
}
</script>

<style lang="scss" scoped>
.size-div {
	max-height: 620px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar:vertical {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(185, 0, 0);
		border-radius: 20px;
		border: 2px solid #f1f2f3;
	}
}

.btn_add {
	bottom: 20px;
	position: fixed;
	right: 20px;
}

.bg-secondary {
	background-color: #98989a;
}

.pagination {
	margin-bottom: 0;
}

.b-logo {
	border-radius: 5px 55px 55px 5px;
	width: 120px;
	z-index: 1;
}

.dt_slider::before {
	content: "";
	background-color: #6c757d;
	height: 29px;
	position: absolute;
	width: 100px;
	bottom: 0px;
	left: -100px;
}

.dt_slider {
	bottom: 0px;
	position: absolute;
	width: calc(100% - 130px);
	border-radius: 0px 0px 5px 0px;
}

.shadow {
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.btn_present {
	position: absolute;
	margin-top: 75px;
	padding: 9px !important;
	border-radius: 30px;
	width: 150px;
}

.wizard-btn {
	margin-top: 40px;
	width: 150px !important;
	height: 45px !important;
	background-color: transparent !important;
	color: #fbd102 !important;
	border-width: 1px !important;
	border-radius: 40px !important;
}

.wizard-btn:hover {
	background-color: #fbd102 !important;
	color: #000 !important;
}

@media (max-width: 425px) {
	.btn_present {
		display: none;
	}

	.vue-form-wizard .wizard-card-footer .wizard-footer-right button {
		display: inline;
	}

}
</style>
