import { render, staticRenderFns } from "./WildCardPage.vue?vue&type=template&id=0fd2943a&scoped=true&"
import script from "./WildCardPage.vue?vue&type=script&lang=js&"
export * from "./WildCardPage.vue?vue&type=script&lang=js&"
import style0 from "./WildCardPage.vue?vue&type=style&index=0&id=0fd2943a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd2943a",
  null
  
)

export default component.exports