export default {
    data() {
        return {
            offline: false,
            event_id: ''
        }
    },
    methods: {
        async mixinCheckOfflineId(id) {
            let offline = localStorage.getItem(`event_${id}_offline`);
            this.offline = offline;
            return offline;
        },
        async mixinCheckOfflineRouterId() {
            let id = this.$route.params.id;
            let offline = false;
            if (id) {
                offline = localStorage.getItem(`event_${id}_offline`);
                this.offline = offline;
            }
            return offline;
        },
        mixinIsOffline: function () {
            alert(`Now printing from a mixin function ${this.event_id}`);
        },
    },
}